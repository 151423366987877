import React from "react";

export default function HowItWorksSection() {
	return (
		<section id="about-us" className='about-us-section' data-color={'white'}>
			<div className='container'>
				<div className={"flex flex-wrap"}>
					<div className={"basis-full"}>
						<h2 className='h2'>About<br/>Us</h2>
					</div>
				</div>
				<div className='subtitle'>
					<em>our vision:</em>
					<br /> Become the #1 recruitment choice for <b>high growth ventures and startups</b>
				</div>
				<div className='flex flex-wrap'>
					<div className='order-2 lg:order-1 lg:pr-12 basis-full lg:basis-1/2'>
						<div className="inner-text">
							<p>The Grit Co is a <b>recruitment company specializing in startups and high-growth scaleups.</b> We provide active and passive recruitment options. Our “Active sourcing as a Service” AsaS approach, at below-market prices, grants you access to the best startup talents.</p>
							<p>our goal is to <b>match</b> the right <b>people, with</b> the <b>right ventures and culture – fostering startup growth one hire at a time!</b></p>
							<p>as matchmakers for belonging, we believe employee engagement allows individual growth, which drives team success and yields startup performance.</p>
							<p><b> we combine the best of both worlds </b> - a traditional approach based on <b> human interactions and values </b>, improved through digital tools and <b> tech </b> to increase reach, results and performance.</p>
							<p>our philosophy is closely related to our brand name "the Grit Co". <b> grit is an underlying characteristic one needs to possess to thrive in today's ever-changing world. </b> while people can learn many new things, adapt to changing environments and cope with adversity - grit is a quality differentiating one talent from another. it is a characteristic we are committed to look for in our search for leaders of today's world.</p>
						</div>
					</div>
					<div className='basis-full lg:basis-1/2 xl:basis-[537px] order-1 lg:order-2'>
						<div>
							<div className='misson'>
								<h4 className='h4'>our mission statement</h4>
								<hr />
								<p className="mb-6">The Grit Co is an Active sourcing as a Service platform on a mission to enable, empower and set startups as well as high-growth scale-ups for sustainable growth and success, one hire at a time.</p>
								<p className='mb-3'>we do it through the combination of:</p>
								<ul>
									<li>a human-centric approach supported by the best available technology</li>
									<li>a below market pricing with cash-back option</li>
									<li>a candidate vs job vs culture assessment</li>
									<li>proprietary algorithms and tech sourcing the very best candidates</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div></div>
			</div>
		</section>
	);
}
