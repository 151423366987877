import React from "react";

export function MemberLinkedin(props) {
	return (
		<div className='member-contact'>
			<div className='flex items-center'>
				<div>
					<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
						<g fill='none' fill-rule='evenodd'>
							<circle fill='#FFF' cx='25' cy='25' r='25' />
							<path
								d='M11.996 37.857h5.9V18.81h-5.9v19.047Zm2.922-21.541c-1.928 0-3.49-1.574-3.49-3.515s1.562-3.515 3.49-3.515c1.926 0 3.487 1.574 3.487 3.515 0 1.94-1.56 3.515-3.487 3.515ZM40 37.857h-5.87v-9.999c0-2.741-1.042-4.273-3.212-4.273-2.36 0-3.594 1.594-3.594 4.273v10h-5.657V18.81h5.657v2.565s1.701-3.147 5.743-3.147c4.04 0 6.933 2.467 6.933 7.57v12.06Z'
								fill='#000'
							/>
						</g>
					</svg>
				</div>
				<div>
					<a href={props.link}  target="_blank" rel="noopener noreferrer">{props.text}</a>
				</div>
			</div>
		</div>
	);
}

export function MemberEmail(props) {
	return (
		<div className='member-contact'>
			<div className='flex items-center'>
				<div>
					<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
						<g fill='none' fill-rule='evenodd'>
							<circle fill='#FFF' cx='25' cy='25' r='25' />
							<path
								d='M37.961 21.645a.519.519 0 0 1 .518-.014c.162.093.26.266.258.454v9.81A3.105 3.105 0 0 1 35.653 35h-20.57a3.107 3.107 0 0 1-3.08-3.105v-9.81a.52.52 0 0 1 .265-.454.514.514 0 0 1 .518.016l10.14 6.37c1.5.938 3.405.938 4.906 0ZM35.653 15a3.106 3.106 0 0 1 3.085 3.105.15.15 0 0 1-.073.13l-12.479 7.852c-.5.31-1.133.31-1.635 0l-12.479-7.85a.154.154 0 0 1-.072-.132A3.105 3.105 0 0 1 15.084 15Z'
								fill='#000'
							/>
						</g>
					</svg>
				</div>
				<div>
					<a href={props.link}>{props.text}</a>
				</div>
			</div>
		</div>
	);
}
