import React from "react";
import data from "../../data/data-2_how-it-works-section.json";

export default function HowItWorksSection() {
	return (
		<section id='how-it-works' className='how-it-works-section' data-color={"white"}>
			<div className='container'>
				<div className={"flex flex-wrap items-end mb-[86px]"}>
					<div className={"basis-full lg:basis-[40%] order-1"}>
						<h2 className='h2'>
							How it
							<br />
							works
						</h2>
					</div>
					<div className={"basis-full order-3 lg:order-2 lg:pl-20 lg:basis-[60%]"}>
						<p className='mb-4 callout'>
							let’s get your startup recruitment ready today! First, it’s about understanding your needs, then we’ll put our grit into
							sourcing your talents. We'll run our matching process before connecting you with your shortlisted candidates. Unlock your
							talents to move on to interviewing and hiring.
						</p>
					</div>
					<div className='basis-full order-2 mb-[38px] lg:mb-0 lg:order-3 subtitle'>as easy as A-B-C</div>
				</div>

				<div className='flex flex-wrap'>
					<div className='pr-8 mb-16 xl:pr-[38px] lg:mb-0 basis-full lg:basis-1/3'>
						<div className='circle'>
							<span>A</span>
						</div>
						<h3 className='h3'>{data.item1.title}</h3>
						<hr />
						<ul>
							{data.item1.list.map((item) => {
								return <li key={item}>{item}</li>;
							})}
						</ul>
					</div>

					<div className='pr-8 mb-16 xl:pr-[38px] lg:mb-0 basis-full lg:basis-1/3'>
						<div className='circle'>
							<span>B</span>
						</div>
						<h3 className='h3'>{data.item2.title}</h3>
						<hr />
						<ul>
							{data.item2.list.map((item) => {
								return <li key={item}>{item}</li>;
							})}
						</ul>
					</div>

					<div className='basis-full lg:basis-1/3'>
						<div className='circle'>
							<span>C</span>
						</div>
						<h3 className='h3'>{data.item3.title}</h3>
						<hr />
						<ul>
							{data.item3.list.map((item) => {
								return <li key={item}>{item}</li>;
							})}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}
