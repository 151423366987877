import React from "react";
import Layout from "../components/layout";
import HeroSection from "../components/sections/1_hero-section";
import HowItWorksSection from "../components/sections/2_how-it-works-section"
import ProductsCandidateSection from "../components/sections/3_products-candidate-section"
import AboutUsSection from "../components/sections/5_about-us-section"
import TeamSection from "../components/sections/6_team-section"
import ClinetsSection from "../components/sections/7_clients-sections"
import { Helmet } from "react-helmet";

const IndexPage = () => {
	return (
		<Layout>
			<Helmet>
				<title>The Grit Company</title>
			</Helmet>
			<main>
				<HeroSection />
				<HowItWorksSection/>
				<ProductsCandidateSection/>
				<AboutUsSection/>
				<TeamSection/>
				<ClinetsSection/>
			</main>
		</Layout>
	);
};

export default IndexPage;
