import React from "react"
import Header from "./sections/0_header";
import Footer from "./sections/8_footer";

export default function Layout(props) {
    return (
        <div>
            <Header/>
            {props.children}
            <Footer/>
        </div>
    )
}
