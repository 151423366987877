import React from "react";
import data from "../../data/data-0_navigation.json";
import data2 from "../../data/data-8-footer.json";
import Button from "../elements/button";

export default function Navigation(props) {
	return (
		<>
			<div className={"navigation"}>
				<div className='flex flex-wrap items-center lg:justify-end'>
					<div className='basis-auto'>
						<ul className={`${props.class}`}>
							{data.map((item) => {
								return (
									<li>
										<a key={item.id} onClick={props.event} href={item.path}>
											{item.name}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
					<div className='basis-auto'>
						<Button link={`mailto:info@thegritco.io`} class='white' label='sign up now' />
					</div>

					<div className='flex space-x-2 lg:hidden basis-full'>
						<div className='social-icon'>
							<div className='flex flex-col ml-auto lg:flex-row basis-full xs:basis-1/2'>
								<div className='mb-16 basis-1/2 lg:mb-0'>
									<fieldset>
										<legend>Social Networks</legend>
										<div className='flex space-x-2'>
											<div className='social-icon'>
												<a href={data2.instagram} target="_blank" rel="noopener noreferrer">
													<svg className='w-[50px] h-[50px]' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
														<g fill='none' fill-rule='evenodd'>
															<circle fill='#FFF' cx='25' cy='25' r='25' />
															<path
																d='M19.762 25.01a5.238 5.238 0 1 1 10.476-.019 5.238 5.238 0 0 1-10.476.019m-2.83.005a8.07 8.07 0 1 0 16.137-.033 8.07 8.07 0 0 0-16.138.033M31.486 16.6a1.886 1.886 0 1 0 3.771-.009 1.886 1.886 0 0 0-3.771.009m-12.81 21.202c-1.533-.067-2.365-.321-2.92-.535a4.897 4.897 0 0 1-1.809-1.172 4.883 4.883 0 0 1-1.178-1.804c-.216-.554-.475-1.385-.547-2.917-.078-1.657-.095-2.154-.103-6.35-.008-4.194.007-4.691.08-6.35.065-1.53.321-2.363.534-2.917a4.876 4.876 0 0 1 1.172-1.81 4.868 4.868 0 0 1 1.805-1.178c.554-.217 1.385-.474 2.916-.547 1.658-.078 2.154-.095 6.349-.103 4.196-.008 4.693.007 6.35.08 1.531.066 2.364.32 2.918.534a4.864 4.864 0 0 1 1.81 1.172c.55.55.892 1.07 1.178 1.806.217.552.474 1.384.546 2.915.08 1.657.097 2.154.105 6.349.008 4.196-.008 4.693-.08 6.35-.067 1.532-.321 2.364-.535 2.92a4.882 4.882 0 0 1-1.172 1.808 4.885 4.885 0 0 1-1.805 1.179c-.553.216-1.385.473-2.916.546-1.657.078-2.153.096-6.35.104-4.195.008-4.691-.008-6.349-.08m-.183-28.407c-1.672.078-2.814.346-3.812.737a7.687 7.687 0 0 0-2.78 1.817 7.7 7.7 0 0 0-1.807 2.787c-.387 1-.65 2.143-.723 3.816-.074 1.676-.09 2.21-.082 6.478.008 4.267.027 4.802.106 6.479.08 1.672.347 2.813.738 3.811a7.693 7.693 0 0 0 1.818 2.78 7.706 7.706 0 0 0 2.787 1.807c1 .387 2.142.65 3.815.723 1.676.074 2.211.09 6.477.082 4.268-.008 4.804-.027 6.48-.106 1.672-.08 2.813-.348 3.812-.738a7.702 7.702 0 0 0 2.78-1.817 7.705 7.705 0 0 0 1.806-2.788c.387-.999.65-2.142.723-3.814.073-1.677.09-2.212.082-6.48-.008-4.266-.027-4.8-.106-6.476-.08-1.674-.348-2.815-.738-3.813a7.708 7.708 0 0 0-1.817-2.78 7.69 7.69 0 0 0-2.787-1.807c-1-.387-2.143-.65-3.815-.723-1.677-.074-2.212-.09-6.48-.082-4.266.008-4.801.026-6.477.107'
																fill='#000'
															/>
														</g>
													</svg>
												</a>
											</div>
											<div className='social-icon'>
												<a href={data2.linkedin} target="_blank" rel="noopener noreferrer">
													<svg className='w-[50px] h-[50px]' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
														<g fill='none' fill-rule='evenodd'>
															<circle fill='#FFF' cx='25' cy='25' r='25' />
															<path
																d='M11.996 37.857h5.9V18.81h-5.9v19.047Zm2.922-21.541c-1.928 0-3.49-1.574-3.49-3.515s1.562-3.515 3.49-3.515c1.926 0 3.487 1.574 3.487 3.515 0 1.94-1.56 3.515-3.487 3.515ZM40 37.857h-5.87v-9.999c0-2.741-1.042-4.273-3.212-4.273-2.36 0-3.594 1.594-3.594 4.273v10h-5.657V18.81h5.657v2.565s1.701-3.147 5.743-3.147c4.04 0 6.933 2.467 6.933 7.57v12.06Z'
																fill='#000'
															/>
														</g>
													</svg>
												</a>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
