import React from "react";
import data from "../../data/data-6_team-section.json";
import { StaticImage } from "gatsby-plugin-image";
import { MemberLinkedin, MemberEmail } from "../elements/member-contact";

export default function HowItWorksSection() {
	return (
		<section id='the-team' className='team-section' data-color={'blue'}>
			<div className='container'>
				<div className={"flex flex-wrap"}>
					<div className={"basis-full"}>
						<h2 className='h2'>
							The <br />
							Team
						</h2>
					</div>
				</div>
				<div className='flex flex-wrap mb-[10px] md:mb-[132px]'>
					<div className='md:pr-16 xl:pr-20 basis-full lg:basis-1/2 xl:basis-6/12'>
						<div className='member mb-[88px] lg:mb-0'>
							<div className='member-image'>
								<StaticImage
									className='mask-1'
									src='../../images/sections/6_team-section/andras-lacko.png'
									quality='100'
									placeholder='dominantColor'
								/>
							</div>
							<div className='title'>
								<h4 className='h4'>{data.member1.name}</h4>
							</div>
							<hr />
							<div className='member-title'>
								<div className="mb-3">{data.member1.title}</div>
								<div>{data.member1.title2}</div>
							</div>
							<div>
								<div className='flex flex-wrap space-x-10 mb-2.5'>
									{data.member1.linkedin && (
										<div className='basis-auto'>
											<MemberLinkedin link={data.member1.linkedin} text='Linkedin' />
										</div>
									)}

									{data.member1.email && (
										<div className='basis-auto'>
											<MemberEmail link={`mailto:${data.member1.email}`} text='Email' />
										</div>
									)}
								</div>
								<div>
									{data.member1.bio.map((item) => {
										return <p key={item}>{item}</p>;
									})}
								</div>
							</div>
						</div>
					</div>

					<div className='md:pr-16 xl:pr-20 basis-full lg:basis-1/2 xl:basis-6/12'>
						<div className='member mb-[88px] md:mb-0'>
							<div className='member-image'>
								<StaticImage
									className='mask-2'
									src='../../images/sections/6_team-section/new-candidate.png'
									quality='100'
									placeholder='dominantColor'
								/>
							</div>
							<div className='title'>
								<h4 className='h4'>{data.member2.name}</h4>
							</div>
							<hr />
							<div className='member-title'>
								<div className="mb-3">{data.member2.title}</div>
								<div>{data.member2.title2}</div>
							</div>
							<div>
								<div className='flex flex-wrap space-x-10 mb-2.5'>
									{data.member2.linkedin && (
										<div className='basis-auto'>
											<MemberLinkedin link={data.member2.linkedin} text='Linkedin' />
										</div>
									)}
									{data.member2.email && (
										<div className='basis-auto'>
											<MemberEmail link={`mailto:${data.member2.email}`} text='Email' />
										</div>
									)}
								</div>
								<div>
									{data.member2.bio.map((item) => {
										return <p key={item}>{item}</p>;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='advisory'>
					<span>
						<em>advisory team</em>
					</span>
				</div>

				<div className='flex flex-wrap mb-[126px]'>
					<div className='md:pr-16 xl:pr-20 basis-full lg:basis-1/2 xl:basis-6/12'>
						<div className='member mb-[88px] lg:mb-0'>
							<div className='member-image'>
								<StaticImage
									className='mask-2'
									src='../../images/sections/6_team-section/claudio-staub.png'
									quality='100'
									placeholder='dominantColor'
								/>
							</div>
							<div className='title'>
								<h4 className='h4'>{data.member3.name}</h4>
							</div>
							<hr />
							<div className='member-title'>
								<div className="mb-3">{data.member3.title}</div>
								<div>{data.member3.title2}</div>
							</div>
							<div>
								<div className='flex flex-wrap space-x-10 mb-2.5'>
									{data.member3.linkedin && (
										<div className='basis-auto'>
											<MemberLinkedin link={data.member3.linkedin} text='Linkedin' />
										</div>
									)}
									{data.member3.email && (
										<div className='basis-auto'>
											<MemberEmail link={`mailto:${data.member3.email}`} text='Email' />
										</div>
									)}
								</div>
								<div>
									{data.member3.bio.map((item) => {
										return <p key={item}>{item}</p>;
									})}
								</div>
							</div>
						</div>
					</div>

					<div className='md:pr-16 xl:pr-20 basis-full lg:basis-1/2 xl:basis-6/12'>
						<div className='member'>
							<div className='member-image'>
								<StaticImage
									className='mask-1'
									src='../../images/sections/6_team-section/daniel-heller.png'
									quality='100'
									placeholder='dominantColor'
								/>
							</div>
							<div className='title'>
								<h4 className='h4'>{data.member4.name}</h4>
							</div>
							<hr />
							<div className='member-title'>
								<div className="mb-3">{data.member4.title}</div>
								<div>{data.member4.title2}</div>
							</div>
							<div>
								<div className='flex flex-wrap space-x-10 mb-2.5'>
									{data.member4.linkedin && (
										<div className='basis-auto'>
											<MemberLinkedin link={data.member1.linkedin} text='Linkedin' />
										</div>
									)}
									{data.member4.email && (
										<div className='basis-auto'>
											<MemberEmail link={`mailto:${data.member4.email}`} text='Email' />
										</div>
									)}
								</div>
								<div>
									{data.member4.bio.map((item) => {
										return <p key={item}>{item}</p>;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr className='divider' />
			</div>
		</section>
	);
}
