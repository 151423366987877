import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function ClientsSection() {
	return (
		<section className='cients-section ' data-color={'blue'}>
			<div className='container'>
				<div className={"flex flex-wrap"}>
					<div className={"basis-full md:basis-[650px]"}>
						<h2 className='h2'>They have trusted us</h2>
					</div>
				</div>
				<div className='flex flex-wrap mb-10'>
					<div className='flex items-center basis-1/2 lg:basis-1/4'>
						<div className='client-image'>
							<StaticImage  src='../../images/sections/7_clients-section/1-cybera-logo.png' alt="client cybera" quality='100' placeholder='none' />
						</div>
					</div>
					<div className='flex items-center pl-8 lg:pl-24 basis-1/2 lg:basis-1/4'>
						<div className='client-image'>
							<StaticImage  src='../../images/sections/7_clients-section/2-ferring-logo.png' alt="client ferring" quality='100' placeholder='none' />
						</div>
					</div>
					<div className='flex items-center lg:pl-24 basis-1/2 lg:basis-1/4'>
						<div className='client-image'>
							<StaticImage  src='../../images/sections/7_clients-section/3-dojo-logo.png' alt="client dojo" quality='100' placeholder='none' />
						</div>
					</div>
					<div className='flex items-center pl-8 lg:pl-24 basis-1/2 lg:basis-1/4'>
						<div className='client-image'>
							<StaticImage src='../../images/sections/7_clients-section/4-virya-logo.png' alt="client virya" quality='100' placeholder='none' />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
