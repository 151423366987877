import React from "react";

export default function ScrollDown(props) {
	return (
		<a href={props.link}>
			<div className="scroll-down">
				<svg className='arrow' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
					<g fill='none' fill-rule='evenodd'>
						<rect fill='#FFF' width='70' height='70' rx='35' />
						<path
							d='m46 37.378-.041-.477c-.77.097-1.497.267-2.204.493a14.22 14.22 0 0 0-2.052.833c-1.317.663-2.544 1.536-3.569 2.627a10.795 10.795 0 0 0-1.665 2.313V22h-1.983v21.167a10.788 10.788 0 0 0-1.665-2.313c-1.025-1.09-2.252-1.964-3.57-2.627-.66-.33-1.345-.608-2.05-.833A12.227 12.227 0 0 0 25 36.9v2.566a9.905 9.905 0 0 1 1.558.254c.598.145 1.19.337 1.763.575 1.144.478 2.215 1.136 3.12 1.974a8.695 8.695 0 0 1 2.128 3.01c.245.576.432 1.181.567 1.804.126.626.195 1.267.227 1.916h2.23c.03-.649.1-1.29.226-1.916.136-.623.322-1.228.567-1.804a8.696 8.696 0 0 1 2.128-3.01c.905-.838 1.976-1.496 3.12-1.974a11.93 11.93 0 0 1 1.763-.575A9.882 9.882 0 0 1 46 39.463v-2.085Z'
							fill='#000'
							stroke='#000'
						/>
					</g>
				</svg>
				<span className='text'>{props.label}</span>
			</div>
		</a>
	);
}
