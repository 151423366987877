import React from "react";
import data from "../../data/data-3_products-candidate-section.json";

import Button from "../elements/button";

export default function HowItWorksSection() {
	return (
		<section id='the-products' className='products-candidate-section' data-color={"yellow"}>
			<div className='container'>
				<div className={"flex flex-wrap items-end"}>
					<div className={"basis-full md:basis-[55%]"}>
						<h2 className='h2'>The Product</h2>
					</div>
					<div className={"basis-full lg:basis-[45%]"}>
						<p className='mb-4 callout'>
							our DISCOVERY package connects you with your personal recruitment sparring partner to kickstart your next recruitment
							journey!
						</p>
					</div>
				</div>

				<div className='grid-parent'>
					<div className='mb-24 xl:mb-0'>
						<div className='product'>
							<div className='flex flex-col justify-between'>
								<div className='flex items-end justify-between title'>
									<h4 className='h4'>Discovery</h4>
								</div>
								<hr />
								<ul>
									{data.item1.list.map((item) => {
										return <li key={item}>{item}</li>;
									})}
								</ul>
							</div>
							<div className='price'>
								<span className='label'>{data.item1.label}</span>
								<div className='flex flex-wrap items-end '>
									<div className='basis-auto'>
										<span className='number'>{data.item1.price}.</span>
									</div>
									<div className='basis-auto'>
										<span className='period'>/{data.item1.period}*</span>
									</div>
								</div>
							</div>
							<p className='small-print'>
								<span>* pre-launch prices are subject to change.</span>
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
