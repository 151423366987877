import React from "react";
import icon1 from "../../images/sections/8_footer-section/1-instagram-icon.svg";
import icon2 from "../../images/sections/8_footer-section/2-linkedin-icon.svg";
import dnLogo from "../../images/sections/8_footer-section/designatives-logo.svg";
import Logo from "../../images/the-grit-co-logo.svg";
import data from "../../data/data-8-footer.json";
import Button from "../elements/button";

export default function Footer() {
	return (
		<section className='footer' data-color={'black'}>
			<div className='container'>
				<div className={"flex flex-wrap"}>
					<div className={"basis-full"}>
						<h2 className='h2'>
							your growth,
							<br />
							our priority
						</h2>
					</div>
				</div>
				<hr className='divider' />
				<div className='flex flex-wrap'>
					<div className='basis-full xs:basis-1/2 lg:basis-1/4'>
						<div className='logo'>
							<img src={Logo} className='logo' alt='the grit company' />
						</div>
					</div>
					<div className='mb-8 basis-full xs:basis-1/2 lg:basis-1/4 lg:mb-0'>
						<div className='flex flex-col justify-between h-full xl:pr-24'>
							<div className='order-2 lg:order-1 basis-auto'>
								<fieldset>
									<legend>Address</legend>
									<p>{data.address}</p>
								</fieldset>
							</div>
							<div className='order-1 mb-8 lg:mb-0 lg:order-2 basis-auto'>
								<Button link={`mailto:info@thegritco.io`} label='sign up now' class='teal' />
							</div>
						</div>
					</div>
					<div className='flex flex-col ml-auto lg:flex-row basis-full xs:basis-1/2'>
						<div className='mb-16 basis-1/2 lg:mb-0'>
							<fieldset>
								<legend>Social Networks</legend>
								<div className='flex space-x-2'>
									<div className='social-icon'>
										<a href={data.instagram} target="_blank" rel="noopener noreferrer">
											<img alt={`social link`} src={icon1} />
										</a>
									</div>
									<div className='social-icon'>
										<a href={data.linkedin} target="_blank" rel="noopener noreferrer">
											<img alt={`social link`} src={icon2} />
										</a>
									</div>
								</div>
							</fieldset>
						</div>
						
						<div className='lg:self-end basis-1/2'>
							<div className='basis-1/2 md:basis-1/4'>
								<div className='flex copy-right'>
									<div className="ml-0 lg:ml-auto">
										<span>Made with</span> ❤ by
										<a className='ml-2' href='https://www.designatives.com/' target="_blank" rel="noopener noreferrer">
											<img className='inline-block h-5' alt='Designatives' src={dnLogo} />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
