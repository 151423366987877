import React from "react";
import ScrollDown from "../elements/scroll-down";

export default function HeroSection() {
	const background1 = require("../../images/sections/1_hero-section/hero-image-1.png").default;
	const background2 = require("../../images/sections/1_hero-section/hero-image-2.png").default;

	return (
		<section className='hero-section' data-color={"blue"}>
			<div className='container'>
				<div className={"flex flex-wrap h-full"}>
					<div className={"basis-full xl:basis-9/12 lg:mb-20"}>
						<h1 className='sm:mb-6 md:mb-10 h1'>
							<b>fostering startup</b><br/><em>& scale-up growth</em><br/> one hire at a time
						</h1>

						<div className='hidden pr-6 lg:flex'>
							<div className='basis-1/2 xl:basis-[606px]'>
								<p className='callout'>
									get matched with startup-world vetted candidates.<br/>The Grit Co qualifies candidates against skills, experience, and
									cultural fitness. Our active sourcing as a service (AsaS) AI powered technology allows you to connect with high-quality
									candidates and hire faster while reducing recruitment costs. Upon successful hire, enjoy a cash back!
									<div className="mt-8">HIRE BETTER – HIRE WISER – HIRE WITH GRIT</div>
								</p>
							</div>
							<div className='lg:pl-6 xl:pl-8 basis-auto scrollto'>
								<ScrollDown label='scroll down for more' link='#how-it-works' />
							</div>
						</div>
					</div>
					<div className={"relative basis-full xl:basis-3/12"}>
						<div className='hero-image-wrapper'>
							<div className='hero-image-1' style={{ backgroundImage: `url(${background1})` }}>
								<div className='flex items-center justify-center h-full'>
									<div className='hero-image-text basis-auto'>it’s all about your people</div>
								</div>
							</div>
							<div className='hero-image-2' style={{ backgroundImage: `url(${background2})` }} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
