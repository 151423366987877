import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import Navigation from "../elements/navigation";

export default function Layout(props) {
	
	let style = 'blue';
	let currentSection = null;

	const [menuOpen, setMenuOpen] = useState(false);
	const [currentColor, setCurrentColor] = useState('blue');


	useEffect(()=>{
		document.addEventListener('scroll', (event) => {

			var sections = document.body.getElementsByTagName('section');

			for (var i = 0; i < sections.length; i++) {
				if(window.scrollY >= sections[i].offsetTop && window.scrollY < sections[i].offsetTop + sections[i].offsetHeight){
					currentSection = sections[i];
				}
				if(currentSection) {
					style = currentSection.dataset.color;
					setCurrentColor(currentSection.dataset.color);
				}

				document.getElementById('header').removeAttribute('class');
				document.getElementById('header').classList.add(style);
			}


			if (window.scrollY >= 100) {
				document.getElementById('header-container').classList.add("remove-padding")
			}
			else {
				document.getElementById('header-container').classList.remove("remove-padding")
			}
		});

	}, [])

	return (
		<header id={"header"} className={`${currentColor} ${menuOpen && 'menu-opened'}`}>
			<div id={"header-container"} className={"container"}>
				<div className='flex items-center justify-between'>
					<div className={"basis-auto"}>
						<Link to='/'>

							<svg className={'logo'} viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
								<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<g id="The-Grit-Company-Copy-3" transform="translate(0.000000, -2040.000000)">
										<g id="THE-PACKAGES" transform="translate(0.000000, 2037.000000)">
											<g id="Group-24" transform="translate(0.000000, 3.000000)">
												<polygon id="rect" points="0.00127879963 0 0.00127879963 54.9883842 23.4161001 54.9883842 0 78.4057631 0 119.998721 41.5955157 120 65.0116158 96.5838999 65.0116158 120 120 120 120 0"/>
												<polygon id="t-letter" points="76.764168 13.5045078 76.764168 17.2603423 71.40344 17.2603423 71.40344 30.1557578 67.127134 30.1557578 67.127134 17.2603423 61.7651271 17.2603423 61.7651271 13.5045078"/>
												<g id="text" transform="translate(54.909738, 12.797076)">
													<path d="M23.4929559,0 L27.5301264,0 L27.5301264,8.21884524 C28.145229,6.47072615 29.7040858,5.17274452 32.2770306,5.17274452 C35.3973017,5.17274452 37.2617916,7.27381231 37.2617916,11.028368 L37.2617916,17.359705 L33.174748,17.359705 L33.174748,11.6677679 C33.174748,9.51682687 32.6082397,8.7380379 30.8140838,8.7380379 C28.8293868,8.7380379 27.5301264,9.68179202 27.5301264,13.0603807 L27.5301264,17.359705 L23.4929559,17.359705 L23.4929559,0 Z" id="Fill-3"/>
													<path d="M47.7327309,10.1554594 C47.6125237,8.83190179 46.6943456,8.1017072 45.2774356,8.1017072 C43.8822652,8.1017072 42.9615294,8.83190179 42.8668983,10.1554594 L47.7327309,10.1554594 Z M45.1572284,5.17325604 C49.2903088,5.17325604 51.6765489,7.41499179 51.6765489,11.1708263 L51.6765489,12.2335088 L42.8668983,12.2335088 C43.0101238,13.7207528 43.8822652,14.5481361 45.3464908,14.5481361 C46.5971568,14.5481361 47.4501162,14.0506831 47.6841365,13.1299474 L51.7008461,13.1299474 C51.369637,15.7770626 49.0307125,17.5955157 45.3464908,17.5955157 C41.3080415,17.5955157 38.8067094,15.0673288 38.8067094,11.3357915 C38.8067094,7.48660457 41.4499883,5.17325604 45.1572284,5.17325604 L45.1572284,5.17325604 Z" id="Fill-5"/>
													<path d="M0,30.8241864 C0,25.3918455 3.40288582,22.0401117 8.59864874,22.0401117 C13.0143439,22.0401117 16.4376905,24.5670198 17.0067563,28.3202967 L12.5897824,28.3202967 C12.3058889,26.7627187 10.7457533,25.7473518 8.83522667,25.7473518 C6.07174066,25.7473518 4.44127113,27.6131205 4.44127113,30.7525736 C4.44127113,33.7053219 5.85818112,35.5723694 9.0705258,35.5723694 C11.5283787,35.5723694 13.0846778,34.5084081 13.249643,32.8804962 L9.23549095,32.8804962 L9.23549095,29.5978175 L15.3519896,29.5978175 C16.74716,29.5978175 17.596283,30.3996249 17.596283,31.8395533 L17.596283,39.0200132 L13.3212558,39.0200132 L13.3212558,36.4931051 C12.6601164,37.7680684 11.1715936,39.3256463 7.96052772,39.3256463 C3.04865833,39.3256463 0,35.9496153 0,30.8241864" id="Fill-7"/>
													<path d="M21.6696434,27.0683518 L29.6749291,27.0683518 L29.6749291,30.6362028 L23.6287645,30.6362028 L23.6287645,39.0200132 L19.5672968,39.0200132 L19.5672968,29.1246617 C19.5672968,27.7780856 20.3230674,27.0683518 21.6696434,27.0683518" id="Fill-9"/>
													<path d="M30.7211151,35.4529295 L34.0754066,35.4529295 L34.0754066,30.6356913 L31.1456766,30.6356913 L31.1456766,27.0678403 L35.7519129,27.0678403 C37.0498945,27.0678403 37.8056651,27.7302585 37.8056651,29.0538162 L37.8056651,35.4529295 L41.1855325,35.4529295 L41.1855325,39.0207805 L30.7211151,39.0207805 L30.7211151,35.4529295 Z" id="Fill-11"/>
													<path d="M51.7002067,39.0201411 L45.2755174,39.0201411 C43.9532386,39.0201411 43.2204864,38.2873889 43.2204864,36.9420917 L43.2204864,30.6350519 L40.3406296,30.6350519 L40.3406296,27.0684797 L43.2204864,27.0684797 L43.2204864,24.9431147 L47.2589356,23.6681515 L47.2589356,27.0684797 L51.2270509,27.0684797 L51.2270509,30.6350519 L47.2589356,30.6350519 L47.2589356,35.4778661 L51.7002067,35.4522901 L51.7002067,39.0201411 Z" id="Fill-13"/>
													<path d="M13.356167,52.3452333 C13.356167,47.4320851 16.7347556,43.7005478 22.1901149,43.7005478 C26.9600375,43.7005478 30.1237878,46.2734926 30.6212409,50.4551674 L26.2042669,50.4551674 C25.8500394,48.6610115 24.3142011,47.5970502 22.2131333,47.5970502 C19.6376308,47.5970502 17.7961593,49.0612758 17.7961593,52.3452333 C17.7961593,55.6509303 19.6376308,57.1164347 22.2131333,57.1164347 C24.3142011,57.1164347 25.8500394,56.029455 26.2042669,54.2340203 L30.6212409,54.2340203 C30.1237878,58.4374347 26.9600375,61.0116584 22.1901149,61.0116584 C16.7347556,61.0116584 13.356167,57.2801211 13.356167,52.3452333" id="Fill-15"/>
													<path d="M38.6386752,57.3510944 C40.2435687,57.3510944 41.3535668,56.4776743 41.3535668,54.6835184 C41.3535668,52.9098233 40.2435687,52.0607004 38.6386752,52.0607004 C36.9839084,52.0607004 35.8751892,52.9098233 35.8751892,54.6835184 C35.8751892,56.4776743 36.9839084,57.3510944 38.6386752,57.3510944 M38.6386752,48.495407 C42.3446365,48.495407 45.4150344,50.454528 45.4150344,54.6835184 C45.4150344,58.955988 42.3446365,60.9189454 38.6386752,60.9189454 C34.8815618,60.9189454 31.8367399,58.955988 31.8367399,54.6835184 C31.8367399,50.454528 34.8815618,48.495407 38.6386752,48.495407" id="Fill-17"/>
													<path d="M35.6540847,21.4223236 C37.247469,21.4223236 38.3446791,22.153797 38.3446791,23.6678957 C38.3446791,25.2088493 37.247469,25.9147467 35.6540847,25.9147467 C34.0607004,25.9147467 33.041497,25.2088493 33.041497,23.6678957 C33.041497,22.153797 34.0607004,21.4223236 35.6540847,21.4223236" id="Fill-19"/>
													<path d="M49.0089729,56.2659051 C50.6023573,56.2659051 51.6995673,56.9973785 51.6995673,58.512756 C51.6995673,60.0524308 50.6023573,60.7583282 49.0089729,60.7583282 C47.4155886,60.7583282 46.3976641,60.0524308 46.3976641,58.512756 C46.3976641,56.9973785 47.4155886,56.2659051 49.0089729,56.2659051" id="Fill-21"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</Link>
					</div>
					<div className={"basis-auto"}>
						<Navigation event={()=>setMenuOpen(false)} class="flex flex-wrap" />
					</div>
					<div className={'basis-auto lg:hidden'}>
						<div tabIndex={0} role="button"  className={'hamburger'} onClick={()=>setMenuOpen(!menuOpen)} onKeyDown={()=>setMenuOpen(!menuOpen)}>
							<div className={'rows'}/>
							<div className={'x'}/>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
