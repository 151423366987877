import React from "react";

export default function Button(props) {
	return (
		<a href={props.link} className={`button ${props.class}`}>
			<span className='label'>{props.label}</span>
			<svg className='icon' viewBox='0 0 29 23'>
				<path
					d='m16.378 1-.477.041c.097.77.267 1.497.493 2.204.225.706.502 1.392.833 2.052.663 1.317 1.536 2.544 2.627 3.569a10.795 10.795 0 0 0 2.313 1.665H1v1.983h21.167a10.788 10.788 0 0 0-2.313 1.665c-1.09 1.025-1.964 2.252-2.627 3.57-.33.66-.608 1.345-.833 2.05A12.227 12.227 0 0 0 15.9 22h2.566a9.905 9.905 0 0 1 .254-1.558c.145-.598.337-1.19.575-1.763.478-1.144 1.136-2.215 1.974-3.12a8.695 8.695 0 0 1 3.01-2.128c.576-.245 1.181-.432 1.804-.567A12.837 12.837 0 0 1 28 12.637v-2.23a12.86 12.86 0 0 1-1.916-.226 10.063 10.063 0 0 1-1.804-.567 8.696 8.696 0 0 1-3.01-2.128c-.838-.905-1.496-1.976-1.974-3.12a11.93 11.93 0 0 1-.575-1.763A9.882 9.882 0 0 1 18.463 1h-2.085Z'
					fill='currentColor'
					stroke='currentColor'
					fill-rule='evenodd'
				/>
			</svg>
		</a>
	);
}